<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row id="form-longe-avppsc">
      <b-col>
        <b-row>
          <b-col>
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye borderD"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  id="avppsc-direito"
                  :value="form.fields.olhoDireito"
                  :disabled="!canEdit"
                  @input="el => debounceInput('olhoDireito', el.target.value)"
                  @click="setActiveForm('avscDireito')"
                  type="text"
                  class="form-control borderDInput"
                  placeholder="Resultado"
                />
              </div>
              <MultipleEyesOptions
                :form="form"
                :options="options"
                activeInput="avscDireito"
                :setActiveInput="this.setActiveForm"
                @select="({ key, value }) => this.updateForm(key, value)"
                @blur="updateMedicalRecord(form)"
                v-if="activeForm==='avscDireito' && canEdit"/>
            </div>
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye borderE"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  id="avppsc-esquerdo"
                  :value="form.fields.olhoEsquerdo"
                  :disabled="!canEdit"
                  @input="el => debounceInput('olhoEsquerdo', el.target.value)"
                  @click="setActiveForm('avscEsquerdo')"
                  type="text"
                  class="form-control borderEInput"
                  placeholder="Resultado"
                />
              </div>
              <MultipleEyesOptions
                :form="form"
                :options="options"
                activeInput="avscEsquerdo"
                :setActiveInput="this.setActiveForm"
                @select="({ key, value }) => this.updateForm(key, value)"
                @blur="updateMedicalRecord(form)"
                v-if="activeForm==='avscEsquerdo' && canEdit"/>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <button
          class="btn blue-underline button-copy center"
          @click="copyEyeValues"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
        <v-copy-icon/>
      </button>
    </b-row>
  </FormWrapper>
</template>

<script>
import { track } from '@/utils/mixPanel'
import { debounce } from 'lodash';
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import EyeFill from '@/assets/icons/eye-fill.svg'
import MultipleEyesOptions from '@/components/Attendance/Forms/Components/MultipleEyesOptions'
import Copy from '@/assets/icons/copy.svg'

export default {
  components: {
    FormWrapper,
    EyeFill,
    MultipleEyesOptions,
    'v-copy-icon' : Copy,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.avppscAcuidadeVisualParaPertoSemCorrecao,
    })
  },
  data() {
    return {
      activeForm: String,
      options: [
        ['J1 (0,37m)', 'J2 (0,50m)', 'J3 (0,67m)'],
        ['J4 (0,75m)', 'J5 (1,00m)', 'J6 (1,25m)']
      ]
    }
  },
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/avppscAcuidadeVisualParaPertoSemCorrecao', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    setActiveForm(FormName) {
      this.activeForm = FormName
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.form.fields.olhoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
      track('copy_eye_values', {
          'module': 'Acuidade Visual Para Perto Sem Correcao'
      });
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.olhoDireito = null
      this.form.fields.olhoEsquerdo = null
    }
  }
}
</script>
<style lang="scss" scoped>
  .borderD {
    border-radius: 8px 0 0 0 !important;
    border-bottom: 0px solid var(--neutral-300) !important;
  }
  .borderE {
    border-radius: 0 0 0 8px !important;
  }
  .borderDInput {
    border-radius: 0 8px 0 0 !important;
    border-bottom: 0px solid var(--neutral-300) !important;
  }
  .borderEInput {
    border-radius: 0 0 8px 0 !important;
  }
</style>